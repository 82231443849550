import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from 'components/Navbars/ITCreativeNav';
import AllNews from 'components/Blog/AllNews';
import Footer from 'components/Saas/Footer';
import SideMenu from 'components/ITCreative/SideMenu';

const PageBlog5 = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current, true);
  }, [navbarRef]);

  return (
    <MainLayout>
      {/* <TopNav  /> */}
      <SideMenu />
      <Navbar navbarRef={navbarRef} />
      <main className="blog-page style-5 color-5">
            
        <AllNews style="5" />
      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title> Read Our Blog for Current Updates </title>
<meta name="description" content="We regularly post current updates about our activities, milestones, corporate social responsibilities, awards, and lots more on our Blog. Enjoy your read
" />

      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default PageBlog5;